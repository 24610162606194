<template>
    <fragment>
        <li @click="start">
            <v-icon left color="light-blue">fa-stopwatch</v-icon>
            Časová dostupnost SaP
        </li>
    </fragment>
</template>

<script>
    import {appBus} from "../../main";

    export default {
        name: 'sap-context-menu',
        props: [
            'data',
        ],
        methods: {
            start() {
                let payload = {
                    coordinates: this.data.coordinates,
                };
                appBus.$emit('ON_SAP_COMPONENT_START', payload);
            },
        },
    }
</script>
